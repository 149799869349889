import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import { Section, Box, Title, Text, ButtonIcon } from "../../components/Core";
import Availability from "../../components/Availability";

import {
  Button,
  LinkContact,
} from "../../components/Core";

const CTA = () => {
  const gContext = useContext(GlobalContext);
  return (
    <>
      <Section>
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="10" xl="7">
              <Availability />
              <div className="text-center my-5">
                <Title>Interested in my work?<br /> Let's talk.</Title>
              </div>
              <div className="text-center">
                <a href="mailto:lisa.hinterholzer@icloud.com">
                  <ButtonIcon
                    /*onClick={(e) => {
                      e.preventDefault();
                      gContext.toggleContact();
                    }}*/
                  >
                    email me
                  </ButtonIcon>
                </a>
              </div>


              <Box
                className="text-center d-flex justify-content-center"
                mt={["3rem", null, "4rem"]}
              >
                <div className="d-flex flex-column flex-lg-row justify-content-center">
                  <LinkContact
                    href="https://www.instagr.am/lisamarie.hiho"
                    target="_blank"
                    className="mb-2 mb-lg-0"
                  >
                    <i className="icon big icon-logo-instagram"></i> 
                    savory.design
                  </LinkContact>
                  <span className="divider mx-2 mx-lg-4 d-none d-lg-block">
                    |
                  </span>
                  <LinkContact
                    href="https://www.linkedin.com/in/lisa-marie-hinterholzer-337b69200/"
                    target="_blank"
                  >
                    <i className="icon big icon-logo-linkedin"></i> 
                    Lisa Hinterholzer
                  </LinkContact>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default CTA;
